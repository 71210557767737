import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import scrollTo from 'gatsby-plugin-smoothscroll';

const useStyles = makeStyles((theme) => ({
  ul: {
    color: 'red',
  },
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiPaginationItem-root': {
      fontFamily: 'Montserrat',
      fontWeight: '500',
      fontSize: '19px',
      lineHeight: '20px',
      color: '#1F1F1F',
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent',
      fontFamily: 'Montserrat',
      fontWeight: '700',
      fontSize: '22px',
      lineHeight: '20px',
      color: '#c43c42',
    },
  },
}));

export default function CategoriesPagination({
  productcategoriesAllPagination,
  setMyproductsrender,
  setGeturlproducts,
  listproductscategories,
  urlproductsPagination,
  productcategoriesThisCat,
  productSearchValue,
}) {
  const classes = useStyles();

  const [page, setPage] = React.useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);

    if (listproductscategories == 'no' || listproductscategories == 'all') {
      productcategoriesThisCat = '';
      setGeturlproducts(
        urlproductsPagination +
          '?page=' +
          value +
          '&product__category=' +
          productcategoriesThisCat,
      );
      setMyproductsrender(
        urlproductsPagination +
          '?page=' +
          value +
          '&product__category=' +
          productcategoriesThisCat,
      );
    }

    if (listproductscategories == 'yes') {
      setGeturlproducts(
        urlproductsPagination +
          '?page=' +
          value +
          '&product__category=' +
          productcategoriesThisCat,
      );
      setMyproductsrender(
        urlproductsPagination +
          '?page=' +
          value +
          '&product__category=' +
          productcategoriesThisCat,
      );
    }

    if (listproductscategories == 'search') {
      setGeturlproducts(
        urlproductsPagination +
          '?page=' +
          value +
          '&search=' +
          productSearchValue,
      );
      setMyproductsrender(
        urlproductsPagination +
          '?page=' +
          value +
          '&search=' +
          productSearchValue,
      );
    }

    scrollTo('#ourStoresTemplateId');
  };

  const allPagination = Math.ceil(productcategoriesAllPagination / 25);

  return (
    <div className={classes.root}>
      <Pagination
        count={allPagination}
        page={page}
        onChange={handleChangePage}
        className="prueba"
      />
    </div>
  );
}
