import './StartOrderingNow.css';

import { Box, Grid, Hidden } from '@material-ui/core';

import ImgAppStoreFluid150 from '../../../components/imgComponents/imgAppStoreFluid150';
import ImgGooglePlayFluid150 from '../../../components/imgComponents/imgGooglePlayFluid150';
import React from 'react';
import SonHand from '../../../images/sonHand.png';
import { useTranslation } from 'react-i18next';

const StartOrderingNow = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="divSonRectangle">
        <Hidden xsDown>
          <Grid container direction="row" justify="center">
            <Box className="sonMainBox">
              <Box align="left" className="boxSonText">
                {t('ourstores.template.startorderingnow')}
              </Box>
              <Box>
                <Grid container direction="row">
                  <Box width="161px">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.shapya.app"
                      target="_blank"
                    >
                      <ImgGooglePlayFluid150 />
                    </a>
                  </Box>
                  <Box width="158px">
                    <a
                      href="https://apps.apple.com/us/app/id1522176109"
                      target="_blank"
                    >
                      <ImgAppStoreFluid150 />
                    </a>
                  </Box>
                </Grid>
              </Box>
            </Box>
            <Box className="boxSonHand">
              <img
                src={SonHand}
                alt="Start Ordering Now"
                className="sonHandImg"
              />
            </Box>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Box className="boxSonHand">
              <img
                src={SonHand}
                alt="Start Ordering Now"
                className="sonHandImg"
              />
            </Box>
            <Box className="sonMainBox">
              <Box align="left" className="boxSonText">
                {t('ourstores.template.startorderingnow')}
              </Box>
              <Box>
                <Grid container direction="row">
                  <Box width="161px">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.shapya.app"
                      target="_blank"
                    >
                      <ImgGooglePlayFluid150 />
                    </a>
                  </Box>
                  <Box width="158px">
                    <a
                      href="https://apps.apple.com/us/app/id1522176109"
                      target="_blank"
                    >
                      <ImgAppStoreFluid150 />
                    </a>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Hidden>
      </div>
    </div>
  );
};

export default StartOrderingNow;
