import './BranchStoreHead.css';

import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import Bakery from '../../../images/bakery.png';
import Butcher from '../../../images/butcher.png';
import Candies from '../../../images/candies.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Groceries from '../../../images/groceries.png';
import ImgAppStoreFluid150 from '../../../components/imgComponents/imgAppStoreFluid150';
import ImgGooglePlayFluid150 from '../../../components/imgComponents/imgGooglePlayFluid150';
import ItalianPiazzaV1 from '../../../images/italianRestaurant.png';
import Restaurants from '../../../images/restaurants.png';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const BranchStoreHead = ({
  name,
  address,
  description,
  logo,
  mainbg,
  geturl,
}) => {
  const { t } = useTranslation();

  const BackgroundImg = () => {
    switch (mainbg) {
      case 'Butcher Shops':
        return Butcher;
      case 'Candy Shops':
        return Candies;
      case 'Groceries':
        return Groceries;
      case 'Bakery':
        return Bakery;
      case 'Restaurants':
        return Restaurants;
      case 'Italian':
        return ItalianPiazzaV1;

      default:
        return Groceries;
    }
  };

  const mainbgStyle = {
    backgroundImage: `url(${BackgroundImg(mainbg)})`,
  };

  const [responseSocialMedia, setResponseSocialMedia] = useState([]);

  useEffect(() => {
    const getSocialMedia = async () => {
      const dataSocialMedia = await axios.get(geturl);
      setResponseSocialMedia(dataSocialMedia.data.socialMedia);
    };
    getSocialMedia();
  }, [geturl]);

  let socialMediaIcon = '';

  const SocialMediaLinks = (socialMediaIcon) => {
    switch (socialMediaIcon) {
      case 'facebook':
        return faFacebook;
      case 'instagram':
        return faInstagram;
      case 'youtube':
        return faYoutube;

      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Grid className="branchStoreHeadMainGrid" style={mainbgStyle}>
        <Box className="branchStoreLinksBox">
          {responseSocialMedia.length > 0 ? (
            <p className="branchStoreFollowText">
              {t('ourstores.template.followthistore')}
            </p>
          ) : null}
          <p className="branchStoreSocialMediaIcons">
            {responseSocialMedia.map((branchStoreSocialMedia) => (
              <span className="branchStoreSocialMediaIcons">
                {(socialMediaIcon = branchStoreSocialMedia.name) &&
                  branchStoreSocialMedia.name != 'website'}
                {branchStoreSocialMedia.name == socialMediaIcon ? (
                  <a
                    href={branchStoreSocialMedia.url}
                    target="_blank"
                    className="branchStoreSocialMediaBtn"
                  >
                    <FontAwesomeIcon
                      icon={SocialMediaLinks(socialMediaIcon)}
                      className="storesSocialMediaIcons"
                    />
                  </a>
                ) : null}
              </span>
            ))}
          </p>
          <p className="branchStoreWebsite">
            {responseSocialMedia.map((branchStoreSocialMedia) => (
              <span>
                {branchStoreSocialMedia.name === 'website' &&
                branchStoreSocialMedia.url !== '' ? (
                  <a
                    href={
                      'https://' +
                      branchStoreSocialMedia.url
                        .replace('https://', '')
                        .replace('http://', '')
                    }
                    target="_blank"
                    className="storeWebsiteLink"
                  >
                    {branchStoreSocialMedia.url
                      .replace('https://', '')
                      .replace('http://', '')}
                  </a>
                ) : null}
              </span>
            ))}
          </p>
        </Box>

        <Box className="boxMainImageStoreText">
          <span className="boxMainImageStoreTextName">{name}</span>
          <br />
          <Grid container className="gridStoreTextDescription">
            <span className="boxMainImageStoreTextDescription">
              {description}
            </span>
            <Box>
              <span className="dot"></span>
            </Box>
            <span className="boxMainImageStoreTextAddress">{address}</span>
            <Box width="138px" align="center" className="googlePlayBox">
              <a
                href="https://play.google.com/store/apps/details?id=com.shapya.app"
                target="_blank"
              >
                <ImgGooglePlayFluid150 />
              </a>
            </Box>
            <Box width="135px" align="center" className="appstorePlayBox">
              <a
                href="https://apps.apple.com/us/app/id1522176109"
                target="_blank"
              >
                <ImgAppStoreFluid150 />
              </a>
            </Box>
          </Grid>
          <Grid container direction="row" className="gridPlayAppStoreMdDown">
            <Box width="138px" align="center" className="googlePlayBoxMdDown">
              <a
                href="https://play.google.com/store/apps/details?id=com.shapya.app"
                target="_blank"
              >
                <ImgGooglePlayFluid150 />
              </a>
            </Box>
            &nbsp;&nbsp;
            <Box width="135px" align="center" className="appstorePlayBoxMdDown">
              <a
                href="https://apps.apple.com/us/app/id1522176109"
                target="_blank"
              >
                <ImgAppStoreFluid150 />
              </a>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Box align="left">
        <Box className="boxExtAvatar">
          <Box className="boxIntAvatar">
            <img src={logo} className="boxIntLogo" />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BranchStoreHead;
