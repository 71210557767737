import './BranchStoreCategories.css';

import { Grid, ListItem, ListItemText } from '@material-ui/core';

import React from 'react';

export default function BranchStoreCategories({
  geturlproductsthiscat,
  myproductcat,
  selectedIndex,
  classes,
  handleListItemClick,
  handleCategoryProductsList,
  setProductcategoriesAllPagination,
  setProductcategoriesThisCat,
}) {
  return (
    <ListItem
      button
      selected={selectedIndex === myproductcat.id}
      onClick={(event) => {
        handleListItemClick(event, myproductcat.id),
          handleCategoryProductsList(
            geturlproductsthiscat.concat(myproductcat.id).join(''),
            myproductcat.name,
            setProductcategoriesAllPagination(myproductcat.productCount),
            setProductcategoriesThisCat(myproductcat.id),
          );
      }}
    >
      <ListItemText
        disableTypography
        primary={
          <Grid container direction="row" justify="space-between">
            <div>{myproductcat.name}</div>
            <div className="listProductCount">{myproductcat.productCount}</div>
          </Grid>
        }
        className={classes.listitemtextcategory}
      />
    </ListItem>
  );
}
