import '../i18n';
import './ourStoresTemplate.css';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  Divider,
  Drawer,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { BackToTop } from 'material-ui-back-to-top';
import BranchStoreCategories from './components/ourStoresTemplate/BranchStoreCategories';
import BranchStoreHead from './components/ourStoresTemplate/BranchStoreHead';
import CategoriesPagination from './components/ourStoresTemplate/CategoriesPagination';
import Footer from '../components/Footer';
import ImgLogoShapyaRedFluidLimit from '../components/imgComponents/imgLogoShapyaRedFluidLimit';
import LinearProgress from '@material-ui/core/LinearProgress';
import NavbarWhite from '../components/navbar/NavbarWhite';
import ProductsSearchBar from './components/ourStoresTemplate/ProductsSearchBar';
import ReactPlayer from 'react-player';
import SEO from '../components/seo';
import Skeleton from '@material-ui/lab/Skeleton';
import StartOrderingNow from './components/ourStoresTemplate/StartOrderingNow';
import ViewListIcon from '@material-ui/icons/ViewList';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: '#1f1f1f',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.94px',
    '& .Mui-selected': {
      color: '#c43c42',
      fontWeight: '700',
    },
  },

  boxDescription: {
    padding: '25px 0px',
  },
  boxVideo: {},
  boxData: {
    fontWeight: '700',
  },
  addressIcon: {
    color: '#c43c42',
  },
  card: {
    maxWidth: '362px',
    margin: theme.spacing(2),
  },
  cardActionArea: {
    border: 'solid 2px #f5f5f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '183px',
  },
  imgCard: {
    maxHeight: '170px',
    maxWidth: '170px',
  },
  storeName: {
    lineHeight: '1.3',
    fontSize: '12px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    textAlign: 'left',
  },
  cardButton: {
    fontFamily: 'Montserrat',
    border: 'solid 2.3px #c43c42',
    borderRadius: '25px',
    color: '#c43c42',
    fontWeight: '700',
    textTransform: 'none',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  titleOurStores: {
    fontSize: '60px',
    fontWeight: '700',
    fontFamily: 'Century Gothic',
  },
  boxCard: {
    maxWidth: '362px',
  },
  buttonrender: {
    fontFamily: 'Montserrat',
    color: '#c43c42',
    fontWeight: '700',
    textTransform: 'none',
    padding: '5px',
    margin: '0px 10px',
  },
  loadingstatus: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
  },
  linearprogress: {
    filter: 'grayscale(100%)',
    width: '100%',
  },
  ourproductstitle: {
    color: '#000',
  },
  buttoncategory: {
    textDecoration: 'none',
    textTransform: 'none',
    fontFamily: 'Montserrat',
    color: '#7b7b7b',
    borderRadius: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: '#c43c42',
      borderBottom: 'solid #c43c42',
    },
    '&:focus': {
      color: '#c43c42',
      borderBottom: 'solid #c43c42',
    },
  },
  listitemtextcategory: {
    fontSize: '16px',
    padding: '0px',
    margin: '0px',
  },
  avatarstyles: {
    color: '#000',
  },
  listitemtextcategorytitle: {
    color: '#c43c42',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '29px',
  },
  categoriesbutton: {
    textTransform: 'none',
    fontFamily: 'Montserrat',
  },
  boxcategorynamerv: {
    color: '#c43c42',
    fontWeight: '600',
  },
}));

export default function OurStoresPost() {
  const { t } = useTranslation();

  const location = useLocation();
  let storeUrlSlug = location.pathname.replace('/', '');

  const geturlForId = `https://app.shapya.com/api/branch-stores/?slug=${storeUrlSlug}`;

  const [mainStoreId, setMainStoreId] = useState();
  const [seoStoreTitle, setSeoStoreTitle] = useState('');
  const [seoStoreKeywords, setSeoStoreKeywords] = useState('');
  const [storeMainBg, setStoreMainBg] = useState('');

  const myfunc = async () => {
    const resGetDataId = await axios.get(geturlForId);
    setMainStoreId(resGetDataId.data.results[0].id);
    setSeoStoreTitle(resGetDataId.data.results[0].store.name);
    setSeoStoreKeywords(
      resGetDataId.data.results[0].store.name +
        ',' +
        resGetDataId.data.results[0].store.description +
        ',' +
        resGetDataId.data.results[0].store.storeType.name +
        ',' +
        resGetDataId.data.results[0].address.description +
        ' in ' +
        resGetDataId.data.results[0].address.city +
        ' - ' +
        resGetDataId.data.results[0].address.state,
    );
    setStoreMainBg(resGetDataId.data.results[0].store.storeType.name);
  };
  myfunc();

  const stateStoreId = mainStoreId;

  const geturl = `https://app.shapya.com/api/branch-stores/${stateStoreId}/`;
  const geturlproductsStore = `https://app.shapya.com/api/branch-stores/${stateStoreId}/products/?page=1`;
  const stateGeturlproducts = `https://app.shapya.com/api/branch-stores/${stateStoreId}/products/`;
  const stateGeturlproductcategories = `https://app.shapya.com/api/branch-stores/${stateStoreId}/product-categories/`;
  const geturlproductcategoriesStore = `https://app.shapya.com/api/branch-stores/${stateStoreId}/product-categories/`;
  const stateGeturlproductsthiscat = `https://app.shapya.com/api/branch-stores/${stateStoreId}/products/?product__category=`;
  const geturlproductsthiscatStore = `https://app.shapya.com/api/branch-stores/${stateStoreId}/products/?product__category=`;
  const stateGeturlproductssearch = `https://app.shapya.com/api/branch-stores/${stateStoreId}/products/?search=`;

  const seotitle = seoStoreTitle;
  const seokeywords = seoStoreKeywords;
  const storeSlug = storeUrlSlug;

  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [logo, setLogo] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();
  const [storevideos, setStorevideos] = useState([]);

  const mainbg = storeMainBg;

  const urlproductsPagination = stateGeturlproducts;
  const [myproducts, setMyproducts] = useState([]);
  const [geturlproducts, setGeturlproducts] = useState([
    stateGeturlproducts + '?page=1',
  ]);

  const [geturlproductsNext, setGeturlproductsNext] = useState([]);
  const [geturlproductsprev, setGeturlproductsprev] = useState([]);
  const [myproductsrender, setMyproductsrender] = useState([]);
  const [myproductsrendernext, setMyproductsrendernext] = useState([]);
  const [myproductsrendercount, setMyproductsrendercount] = useState([]);

  const [arrayProductsNext, setArrayProductsNext] = useState();
  const [categorias, setCategorias] = useState([]);

  const [myproductcategories, setMyproductcategories] = useState([]);
  const [myproductcategoriesCount, setMyproductcategoriesCount] = useState([]);
  const [myproductcategoriesCountCat, setMyproductcategoriesCountCat] =
    useState([]);
  const [myproductcategoriesCountSearch, setMyproductcategoriesCountSearch] =
    useState([]);
  const [myproductcategoriesname, setMyproductcategoriesname] = useState([]);
  const [geturlproductcategories, setGeturlproductcategories] = useState([
    stateGeturlproductcategories,
  ]);
  const [geturlprodcatnext, setGeturlprodcatnext] = useState([]);
  const [geturlprodallcatnext, setGeturlprodallcatnext] = useState([]);
  const [geturlproductsthiscat, setGeturlproductsthiscat] = useState([
    stateGeturlproductsthiscat,
  ]);
  const [listproductscategories, setListproductscategories] = useState('no');

  const [categorynamerv, setCategorynamerv] = useState('All');

  const [productcategoriesAllPagination, setProductcategoriesAllPagination] =
    useState([]);
  const [productcategoriesThisCat, setProductcategoriesThisCat] = useState([]);

  const [productSearchValue, setProductSearchValue] = useState('');

  const [inputSearchProductsValue, setInputSearchProductsValue] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mainStoreId > 0) {
      setGeturlproductsthiscat([
        `https://app.shapya.com/api/branch-stores/${mainStoreId}/products/?product__category=`,
      ]);

      async function getData() {
        const res = await axios.get(geturl);

        setName(res.data.store.name);
        setDescription(res.data.store.description);
        setLogo(res.data.store.logo);
        setAddress(res.data.address.address);
        setCity(res.data.address.city);
        setState(res.data.address.state);
        setZipcode(res.data.address.zipcode);
        setStorevideos(res.data.videos);

        setLoading(true);

        if (listproductscategories === 'no') {
          if (document.getElementById('listitemSearchresults')) {
            document.getElementById('listitemSearchresults').style.display =
              'none';
          }
          setInputSearchProductsValue('');
          document.getElementById('productsearchIdNotfound').style.display =
            'none';
          document.getElementById('linearProgressBar').style.display = '';
          const res2 = await axios.get(geturlproductsStore);
          setMyproducts(res2.data.results);
          setGeturlproducts(res2.data.next);
          setMyproductsrendernext(res2.data.results);
          setMyproducts(res2.data.results);
          setMyproductcategoriesCount(res2.data.count);
          setProductcategoriesAllPagination(res2.data.count);
          document.getElementById('linearProgressBar').style.display = 'none';
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }

        const res3 = await axios.get(geturlproductcategoriesStore);
        setMyproductcategories(res3.data.results);

        const res5 = await axios.get(geturlproductsthiscatStore);

        if (listproductscategories === 'yes') {
          if (document.getElementById('listitemSearchresults')) {
            document.getElementById('listitemSearchresults').style.display =
              'none';
          }
          setInputSearchProductsValue('');
          document.getElementById('productsearchIdNotfound').style.display =
            'none';
          document.getElementById('linearProgressBar').style.display = '';
          const res4 = await axios.get(myproductsrender);
          setMyproducts(res4.data.results);
          setGeturlprodcatnext(res4.data.next);

          if (geturlprodcatnext != null) {
            setMyproducts(res4.data.results);
            if (res4.data.previous != null) {
              setMyproducts(res4.data.results);
            }
          }
          document.getElementById('linearProgressBar').style.display = 'none';
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }

        if (listproductscategories === 'all') {
          if (document.getElementById('listitemSearchresults')) {
            document.getElementById('listitemSearchresults').style.display =
              'none';
          }
          setInputSearchProductsValue('');
          document.getElementById('productsearchIdNotfound').style.display =
            'none';
          document.getElementById('linearProgressBar').style.display = '';
          const res4 = await axios.get(myproductsrender);
          setMyproducts(res4.data.results);
          setGeturlprodallcatnext(res4.data.next);
          setProductcategoriesAllPagination(myproductcategoriesCount);
          if (geturlprodallcatnext != null) {
            setMyproducts(res4.data.results);
            if (res4.data.previous != null) {
              setMyproducts(res4.data.results);
            }
          }
          document.getElementById('linearProgressBar').style.display = 'none';
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }

        if (listproductscategories === 'search') {
          if (document.getElementById('listitemSearchresults')) {
            document.getElementById('listitemSearchresults').style.display = '';
          }
          document.getElementById('linearProgressBar').style.display = '';
          const res4 = await axios.get(myproductsrender);
          setMyproducts(res4.data.results);
          setGeturlprodallcatnext(res4.data.next);
          setMyproductcategoriesCountSearch(res4.data.count);
          setProductcategoriesAllPagination(res4.data.count);
          if (geturlprodallcatnext != null) {
            setMyproducts(res4.data.results);
            if (res4.data.previous != null) {
              setMyproducts(res4.data.results);
            }
          }
          document.getElementById('linearProgressBar').style.display = 'none';
          setTimeout(() => {
            setLoading(false);
          }, 3000);
          if (res4.data.count === 0) {
            document.getElementById('productsearchIdNotfound').style.display =
              '';
          } else {
            document.getElementById('productsearchIdNotfound').style.display =
              'none';
          }
        }
      }

      getData();
    } else {
      null;
    }
  }, [myproductsrender, mainStoreId]);

  const handleCategoryProductsList = (idcat, namecat) => {
    const idcaturl = `${idcat}`;

    const namecategory = `${namecat}`;
    setListproductscategories('yes');
    if (idcaturl == null) {
    } else {
      setMyproductsrender(idcaturl);
      setCategorynamerv(namecategory);
    }
  };

  const handleCategoryProductsListAll = (idcatall) => {
    const idcaturlall = `${idcatall}`;
    setListproductscategories('all');
    if (idcaturlall == null) {
    } else {
      setMyproductsrender(idcaturlall);
      setCategorynamerv('All');
    }
  };

  const handleCategoryProductsListSearch = (idcatsearch) => {
    const idcaturlsearch = `${idcatsearch}`;
    setListproductscategories('search');
    if (idcaturlsearch == null) {
    } else {
      setMyproductsrender(idcaturlsearch);
      setCategorynamerv('Search results');
    }
  };

  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    open;
  };

  const arraystorebranchvideos = [];

  const arrayvideosmap = storevideos.map((storeallurlvideos) => {
    arraystorebranchvideos.push(storeallurlvideos.url);
  });

  const urlProductsSearch = stateGeturlproducts;

  return (
    <div className="shapyaMaxWidth">
      <SEO title={seotitle} keywords={[seokeywords]} />
      <BackToTop size="small" buttonPosition={{ bottom: 20, right: 20 }} />
      <NavbarWhite />
      <BranchStoreHead
        name={name}
        address={address}
        description={description}
        logo={logo}
        mainbg={mainbg}
        geturl={geturl}
      />
      <div id="ourStoresTemplateId" className={classes.root}>
        <Hidden smUp>
          <br />
        </Hidden>
        <Grid xs={12}>
          <Box
            mt={1}
            mb={4}
            align="center"
            className={classes.ourproductstitle}
          >
            <h2>{t('ourstores.template.ourproducts')}</h2>
          </Box>
        </Grid>
        <Hidden lgUp>
          <Grid xs={12}>
            <Box mt={4} mb={4} align="center">
              <Button
                className={classes.categoriesbutton}
                onClick={handleDrawer}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatarstyles}>
                    <ViewListIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={t('ourstores.template.categories')}
                  className={classes.listitemtextcategorytitle}
                />
              </Button>
            </Box>
            <Box
              className={classes.boxcategorynamerv}
              align="center"
              mt={2}
              mb={2}
            >
              {categorynamerv}
            </Box>
          </Grid>

          <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
            <div className="divDrawerCategories">
              <List
                component="nav"
                aria-label="secondary mailbox folder"
                subheader={
                  <Box m={2} align="center">
                    <Box width="90px">
                      <Box mb={1}>
                        <ImgLogoShapyaRedFluidLimit />
                      </Box>
                      <img src={logo} />
                    </Box>
                  </Box>
                }
              >
                <Divider />
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={t('ourstores.template.categories')}
                    className={classes.listitemtextcategorytitle}
                  />
                </ListItem>

                <ListItemText
                  button
                  selected={selectedIndex === 1}
                  onClick={(event) => {
                    handleListItemClick(event, 1),
                      handleCategoryProductsListAll(stateGeturlproducts);
                    handleDrawerClose(open);
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Grid container direction="row" justify="space-between">
                        <div>{'All'}</div>
                        <div className="allProductCountDrawer">
                          {myproductcategoriesCount}
                        </div>
                      </Grid>
                    }
                    className={classes.listitemtextcategory}
                  />
                </ListItemText>
                {myproductcategories.map((myproductcat) => (
                  <ListItem
                    button
                    selected={selectedIndex === myproductcat.id}
                    onClick={(event) => {
                      handleListItemClick(event, myproductcat.id),
                        handleCategoryProductsList(
                          geturlproductsthiscat
                            .concat(myproductcat.id)
                            .join(''),
                          myproductcat.name,
                          setProductcategoriesAllPagination(
                            myproductcat.productCount,
                          ),
                          setProductcategoriesThisCat(myproductcat.id),
                        );
                      handleDrawerClose(open);
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Grid container direction="row" justify="space-between">
                          <div className="listProductNameDrawer">
                            {myproductcat.name}
                          </div>
                          <div className="listProductCountDrawer">
                            {myproductcat.productCount}
                          </div>
                        </Grid>
                      }
                      className={classes.listitemtextcategory}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Drawer>
        </Hidden>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <List component="nav" aria-label="secondary mailbox folder">
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={t('ourstores.template.categories')}
                    className={classes.listitemtextcategorytitle}
                  />
                </ListItem>

                <ListItem id="listitemSearchresults">
                  <ListItemText
                    disabled
                    disableTypography
                    primary={
                      <Grid container direction="row" justify="space-between">
                        <div className="searchProductName">
                          {t('productsearch.searchresultstext')}
                        </div>
                        <div className="allProductCount">
                          {myproductcategoriesCountSearch}
                        </div>
                      </Grid>
                    }
                    className={classes.listitemtextcategory}
                  />
                </ListItem>

                <ListItem
                  button
                  selected={selectedIndex === 1}
                  onClick={(event) => {
                    handleListItemClick(event, 1),
                      handleCategoryProductsListAll(stateGeturlproducts);
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Grid container direction="row" justify="space-between">
                        <div className="allProductName">{'All'}</div>
                        <div className="allProductCount">
                          {myproductcategoriesCount}
                        </div>
                      </Grid>
                    }
                    className={classes.listitemtextcategory}
                  />
                </ListItem>
                {myproductcategories.map((myproductcat) => (
                  <BranchStoreCategories
                    geturlproductsthiscat={geturlproductsthiscat}
                    myproductcat={myproductcat}
                    selectedIndex={selectedIndex}
                    classes={classes}
                    handleListItemClick={handleListItemClick}
                    handleCategoryProductsList={handleCategoryProductsList}
                    setProductcategoriesAllPagination={
                      setProductcategoriesAllPagination
                    }
                    setProductcategoriesThisCat={setProductcategoriesThisCat}
                  />
                ))}
              </List>
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={7} className="ourProductsCards">
            <ProductsSearchBar
              urlProductsSearch={urlProductsSearch}
              setGeturlproducts={setGeturlproducts}
              myproducts={myproducts}
              setMyproductsrender={setMyproductsrender}
              setMyproductcategoriesCountSearch={
                setMyproductcategoriesCountSearch
              }
              handleCategoryProductsListSearch={
                handleCategoryProductsListSearch
              }
              setProductSearchValue={setProductSearchValue}
              inputSearchProductsValue={inputSearchProductsValue}
              setInputSearchProductsValue={setInputSearchProductsValue}
            />
            <Box mt={8} mb={8} align="center" id="linearProgressBar">
              <LinearProgress className={classes.linearprogress} />
              <br />
              <h3>{t('ourstores.template.loadingproducts')}</h3>
            </Box>
            <Grid container spacing={3}>
              {myproducts.map((myproduct) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Box align="center">
                    <Box className={classes.boxCard} id="myUL">
                      {loading ? (
                        <div>
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            component="span"
                            className="skeletonRect"
                          >
                            {t('productsearch.skeletonloading')}
                          </Skeleton>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            component="span"
                            className="skeletonText"
                          />
                        </div>
                      ) : (
                        <Card className={classes.card} elevation={0}>
                          <CardActionArea className={classes.cardActionArea}>
                            <img
                              src={myproduct.product.images[0].image}
                              className={classes.imgCard}
                            />
                          </CardActionArea>
                          <CardActions>
                            <p className={classes.storeName}>
                              {myproduct.product.name}
                              <br />
                              SKU: {myproduct.product.sku}
                              <br />
                              <b>
                                {myproduct.product.currency.code}{' '}
                                {myproduct.product.currency.symbol}
                                {myproduct.product.price}
                              </b>
                            </p>
                          </CardActions>
                        </Card>
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Grid>
              <Box align="center">
                <br />
                <br />
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Box>
                    <CategoriesPagination
                      listproductscategories={listproductscategories}
                      productcategoriesAllPagination={
                        productcategoriesAllPagination
                      }
                      setMyproductsrender={setMyproductsrender}
                      setGeturlproducts={setGeturlproducts}
                      urlproductsPagination={urlproductsPagination}
                      productcategoriesThisCat={productcategoriesThisCat}
                      productSearchValue={productSearchValue}
                    />
                  </Box>
                </Grid>
                <Box
                  className="productsearchNotfound"
                  id="productsearchIdNotfound"
                  display="block"
                >
                  {t('productsearch.productnotfound')}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={1}></Grid>
          </Hidden>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid container>
          <Grid item xs={12} align="center">
            <Grid item xs={12} md={6}>
              <Box className={classes.boxVideo} p={3}>
                <ReactPlayer
                  url={arraystorebranchvideos}
                  controls
                  width="100%"
                  playing={false}
                  muted={true}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
      </div>
      <StartOrderingNow />
      <Footer />
    </div>
  );
}
