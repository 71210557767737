import './ProductSearchBar.css';

import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '550px',
    margin: '10px 18px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchIcon: {
    color: '#c43c42',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchButton: {
    textTransform: 'none',
    fontFamily: 'Montserrat',
    color: '#c43c42',
    fontWeight: '700',
  },
}));

export default function ProductsSearchBar({
  urlProductsSearch,
  setGeturlproducts,
  setMyproductsrender,
  handleCategoryProductsListSearch,
  setProductSearchValue,
  inputSearchProductsValue,
  setInputSearchProductsValue,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClickProductsSearch = () => {
    setProductSearchValue(inputSearchProductsValue);
    const thisProductsSearch =
      urlProductsSearch + '?page=1&search=' + inputSearchProductsValue;
    setGeturlproducts(thisProductsSearch);
    setMyproductsrender(thisProductsSearch);
  };

  const clearInputProductsSearch = () => {
    setInputSearchProductsValue('');
  };

  return (
    <div className="divSearchBar">
      <Paper
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleCategoryProductsListSearch();
          handleClickProductsSearch();
        }}
        className={classes.root}
      >
        <IconButton className={classes.iconButton} disabled>
          <SearchIcon className={classes.searchIcon} />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder={t('productsearch.searchinputplaceholder')}
          inputProps={{ 'aria-label': 'search for products' }}
          autoComplete="off"
          id="inputSearchProducts"
          required
          value={inputSearchProductsValue}
          onChange={(e) => setInputSearchProductsValue(e.target.value)}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="clear"
          onClick={clearInputProductsSearch}
        >
          <ClearIcon />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <Button
          type="submit"
          aria-label="search"
          className={classes.searchButton}
        >
          {t('productsearch.buttonsearch')}
        </Button>
      </Paper>
    </div>
  );
}
